<template>
  <div>
    <div id="product" v-if="current_active_module">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      />
      <v-data-table
        :headers="headers"
        hide-default-footer
        v-model="selected"
        :single-select="singleSelect"
        show-select
        item-key="product_id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :items="desserts"
        :options.sync="options"
        class="elevation-1"
        @page-count="pageCount = $event"
      >
        <template v-slot:top>
          <div id="product__filter">
            <v-col cols="5">
              <v-select
                :items="sectors"
                dense
                multiple
                item-text="name"
                item-value="key"
                v-model="sector_data"
                label="Sector"
              />
            </v-col>
            <v-col cols="5">
              <v-select
                :items="product_status"
                dense
                multiple
                item-text="value"
                item-value="key"
                v-model="product_status_data"
                label="Status"
              />
            </v-col>
            <v-col cols="4" v-if="displayButtons">
              <v-btn class="mx-2" small color="primary" @click="search">
                <strong>Filter</strong>
              </v-btn>
              <v-btn class="mx-2" small color="default" @click="reset">
                <strong>Reset</strong>
              </v-btn>
            </v-col>
          </div>
          <searchForm
            @data="data"
            @search="search"
            @reset="reset"
            @addCompany="addCompany"
            @removeCompany="removeCompany"
            :formType="'ELECTRINIC_UNPUBLISHED'"
            @showButtons="showButtons"
            :stateName="'EcTempProduct'"
          />
          <v-container class="grey lighten-5" id="product__container">
            <v-row no-gutters>
              <v-col cols="12">
                <CopyToClipboard :muids="selectedEndPointUrls" />
                <div style="float: right">
                  <v-btn
                    color="error"
                    small
                    @click.stop="deleteproduct()"
                    v-show="checkIfOperationExistForModule('delete')"
                    >Delete</v-btn
                  >
                </div>
                <div style="float: right">
                  <v-btn color="primary" small @click.stop="addnewproduct(3)"
                    >Add Product</v-btn
                  >
                </div>
                <div style="float: right">
                  <v-btn
                    color="primary"
                    small
                    @click.stop="massproductupdate()"
                    v-show="checkIfOperationExistForModule('edit')"
                    >Bulk Update</v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </v-container>
        </template>

        <template v-slot:item.product_id="{ item }">
          <span>{{ item.product_id }}</span>
          <div style="display: block">
            <!--
            <v-icon @click="addItem(item)" small class="mr-2"
              title="Publish/Unpublish Product">mdi-circle-edit-outline</v-icon>
              -->
            <v-icon
              @click="addItemV2(item)"
              color="blue darken-2"
              small
              class="mr-2"
              title="Publish/Unpublish Product V2"
              >mdi-circle-edit-outline</v-icon
            >
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-width="200"
              offset-x
              v-if="item.product_status != 11"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dark
                  right
                  size="20"
                  color=""
                  v-bind="attrs"
                  v-on="on"
                  style=""
                  @click="displaypreview(item.product_id)"
                  v-if="pdfFile(item.product_id) == 1"
                  >mdi-file-pdf-box
                </v-icon>
              </template>

              <div
                class="pdfcontent"
                v-if="hover_product_id == item.product_id"
              >
                <div class="pa-2">
                  <v-progress-circular
                    indeterminate
                    size="22"
                    color="blue-grey"
                    v-if="preview_loaded == false"
                  ></v-progress-circular>
                </div>
                <div
                  style="
                    positon: relative;
                    background-color: purple;
                    color: #fff;
                  "
                >
                  <a
                    :href="pdf_link"
                    target="__blank"
                    v-if="preview_loaded == true"
                    style="
                      margin-top: 5px;
                      margin-left: 5px;
                      text-decoration: none;
                      color: #fff;
                    "
                    >View PDF Content
                  </a>
                </div>
                <div v-for="imglist in imagelist" :key="imglist.productID">
                  <v-img
                    v-if="
                      imglist.img_document_default == 1 &&
                      preview_loaded == true
                    "
                    class="border border-dark"
                    :lazy-src="imglist.downlaod_url"
                    :max-height="500"
                    :max-width="400"
                    :src="imglist.downlaod_url"
                    @click="manage_pdf(imglist.productID)"
                    style="cursor: pointer"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>
              </div>
            </v-menu>
          </div>
        </template>
        <template v-slot:item.entry_id="{ item }">
          <span v-if="item.product_status == 11"> --</span>
          <span v-else> {{ item.entry_id }}</span>
        </template>

        <template v-slot:item.company="{ item }">
          <span>{{ companyName(item.product_id) }}</span>
        </template>
        <template v-slot:item.modify_date="{ item }">
          <span>{{ formateDate(item.modify_date) }}</span>
        </template>
        <template v-slot:item.userpopup="{ item }">
          <span
            @click.stop="openlogmodal(item.product_id, item.muid)"
            style="color: blue; cursor: pointer"
            >{{ userName(item.product_id) }}</span
          >

          <v-icon
            style="margin: 10px"
            medium
            color="grey"
            class="ml-2"
            title="Assign Users/Upload Attachments"
            @click="open_comment_dialog(item)"
          >
            mdi-message-text
            
          </v-icon>
        </template>

        <template v-slot:item.mchannel_sector_audience="{ item }">
          <span>{{ mchannelSectorAudience(item) }}</span>
        </template>
        <template v-slot:item.product_status="{ item }">
          <span>{{ displayStatus(item.product_status) }}</span>
        </template>
      </v-data-table>
      <div class="text-center pt-4 pb-4">
        <Pagination
          :isloading="isLoading"
          :startRecord="startRecord"
          :currentPage="currentPage"
          :lastPage="lastPage"
          :lastRecord="lastRecord"
          :totRecords="totRecords"
          :isCurrentPageClass="isCurrentPageClass"
          :perpage="perpage"
          :getLastPageClass="getLastPageClass"
          :totPage="totPage"
          :getPages="getPages"
          @handlePerPage="handlePerPage"
          @paginate="paginate"
          @last="last"
          @getDataByPage="getDataByPage"
          :showPerPage="showPerPage"
        />
      </div>
      <!-- dialog box-->
      <v-dialog v-model="massupdatedialog" max-width="650">
        <MassUpdate
          :selected="selected"
          @massupdatedialogStatus="massupdatedialogStatus"
        />
      </v-dialog>

      <!-- dialog box-->
      <!-- dialog box-->
      <v-dialog
        v-model="comment_dialog"
        max-width="900px"
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
      >
        <v-card>
          <!-- <div class="text-right">
          <v-btn
            icon
            dark
            @click="close_comment_dialog"
          >
            <v-icon color="blue"style="font-size: 20px;">mdi-close</v-icon>
          </v-btn>
           </div> -->
          <!-- <div class="text-center font-weight-medium" style="padding-left:30%;padding-top:12px">
           <h2>Conversation Board</h2>
            </div> -->

          <div
            class="text-center font-weight-medium"
            style="padding-left: 30%; padding-top: 12px"
          >
            <h2>Conversation Board</h2>
          </div>

          <v-card-text>
            <CommentAssignedForm
              :userdata="userdata"
              :comment_data="comment_data"
              :comment_product_id="comment_product_id"
              @close_comment_dialog="close_comment_dialog"
            />
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="productadmindialog" max-width="400">
        <v-card>
          <v-card-title
            class="headline"
            style="padding: 10px; font-size: 16px !important"
          >
            Product Activity Log
          </v-card-title>
          <br />
          <v-data-table
            :headers="userheaders"
            hide-default-footer
            disable-pagination
            :items="logdata"
            class="elevation-1"
          >
            <template v-slot:item.logDate="{ item }">
              <span>{{ formateDate(item.logDate) }}</span>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="green darken-1"
              text
              @click="productadmindialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div id="product_div" v-else>
      <div id="product_loader"></div>
    </div>
  </div>
</template>
<script>
import Pagination from "./component/Pagination.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import searchForm from "./component/searchForm.vue";
import MassUpdate from "./component/MassUpdate";
import CommentAssignedForm from "./component/CommentAssignedForm";
import Tabs from "./component/Tabs.vue";
import { mapGetters } from "vuex";
import CopyToClipboard from "./component/CopyToClipboard.vue";
export default {
  name: "TempProduct",
  components: {
    Loading,
    Pagination,
    searchForm,
    MassUpdate,
    Tabs,
    CommentAssignedForm,
    CopyToClipboard,
  },
  data() {
    return {
      fixed_header: true,
      comment_data: [],
      comment_product_id: null,
      comment_dialog: false,
      comment_close_click: false,
      preview_loaded: false,
      hover_product_id: false,
      menu: null,
      massupdatedialog: false,
      singleSelect: false,
      selected: [],
      filtermuid: "",
      isLoading: true,
      fullPage: true,
      productadmindialog: false,
      logdata: [],
      fullPage: true,
      rowsPerPageItems: [25, 50, 100],
      sortDesc: true,
      sortBy: "modify_date",
      singleSelect: false,
      selected: [],
      search_product_id: "",
      selectedcompanyid: "",
      search_product_name: "",
      selectedstateid: "",
      sort: "",
      loading: true,
      options: {},
      showPerPage: true,
      userheaders: [
        {
          text: "UserName",
          value: "userid",
          width: "40%",
          class: "",
        },
        {
          text: "Action",
          value: "ProductActivity",
          width: "40%",
          class: "",
        },
        {
          text: "Date",
          value: "logDate",
          width: "20%",
          class: "",
          sortable: false,
        },
      ],
      headers: [
        {
          text: "Muid",
          align: "start",
          sortable: true,
          value: "muid",
          width: "10%",
          class: "v-data-table-header",
        },
        {
          text: "Product Id",
          align: "start",
          sortable: true,
          value: "product_id",
          class: "v-data-table-header",
          width: "10%",
        },
        {
          text: "Date",
          value: "modify_date",
          width: "10%",
          class: "v-data-table-header",
        },
        {
          text: "Entry Id",
          value: "entry_id",
          width: "13%",
          class: "v-data-table-header",
        },
        {
          text: "Product",
          value: "product_name",
          //width: "20%",
          sortable: true,
          class: "v-data-table-header",
        },
        {
          text: "Status",
          value: "product_status",
          width: "10%px",
          sortable: true,
          class: "v-data-table-header",
        },
        // {
        //     text: 'DM/TM Source',
        //     value: 'dm_source',
        //     width:"180px",
        //     class: "v-data-table-header",
        // },
        {
          text: "Company",
          value: "company",
          width: "10%",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Sector/Audience",
          value: "mchannel_sector_audience",
          sortable: false,
          width: "20%",
          class: "v-data-table-header",
        },
        {
          text: "Last User",
          value: "userpopup",
          width: "10%",
          sortable: false,
          class: "v-data-table-header",
        },
      ],
      logmuid: "",
      data: "temp_product",
      sectors: [],
      product_status: [],
      displayButtons: true,
    };
  },
  mounted() {
    var view = this,
      raw_data_endpoint = view.$url("PRODUCT_RAW_DATA");
    view.$store.dispatch("EcTempProduct/setProductStatus", []);
    view.$productrouteconst.fetchAllProductStatus(view);
    view.$store
      .dispatch("EcTempProduct/setIsSearch", true)
      .then(function () {
        return view.$store.dispatch("EcTempProduct/setMustNot", {
          key: "product_status",
          value: view.$productrouteconst.APPROVED,
        });
      })
      // .then(function () {
      //   return view.$store.dispatch("EcTempProduct/setMustNot", {
      //     key: "product_status",
      //     value: view.$productrouteconst.JUNK,
      //   });
      // })
      .then(function () {
        return view.$store.dispatch("EcTempProduct/setProductStatus", []);
      })
      .then(function () {
        return view.$store.dispatch("EcTempProduct/setMediaChannels", [
          view.$productrouteconst.EMAIL,
        ]);
      });
    view
      .$fetch({ requiresAuth: true })
      .get(raw_data_endpoint)
      .then((response) => {
        view.sectors = response.data.payload;
      })
      .catch((e) => console.log(e));
    view.product_status = view.$productrouteconst.product_status();
    console.log(
      "length ... " + view.$store.getters["EcTempProduct/getAllowedSectors"]
    );
    //view.$store.dispatch('EcTempProduct/setSectors', sector)
    //alert(view.$store.getters['Rawdata/getSectors'])
  },
  computed: {
    ...mapGetters({
      current_active_module: "Rawdata/getCurrentActiveModule",
      userdata: "Rawdata/getUserData",
    }),
    allowed_sectors: {
      get: function () {
        var view = this;
        return view.$store.getters["EcTempProduct/getAllowedSectors"];
      },
      set: function () {},
    },
    sector_data: {
      get: function () {
        return this.$store.getters["EcTempProduct/getSectors"];
      },
      set: function (arr) {
        var view = this,
          all = view.sectors.filter((e) => e.key !== "0"),
          selected = arr.filter((e) => e === "0"),
          saved = view.$store.getters["EcTempProduct/getSectors"],
          flag = true;
        if (selected.length > 0) {
          if (
            arr.filter((e) => e !== "0").length <
            saved.filter((e) => e !== "0").length
          ) {
            arr = arr.filter((e) => e !== "0");
            flag = false;
          } else {
            all.forEach(function (e) {
              arr.push(e.key);
            });
          }
        }
        if (saved.includes("0") && !arr.includes("0")) {
          if (flag) {
            arr = new Array();
          }
        }
        arr = [...new Set(arr)];
        view.$store.dispatch("EcTempProduct/setSectors", arr);
      },
    },
    product_status_data: {
      get: function () {
        return this.$store.getters["EcTempProduct/getProductStatus"];
      },
      set: function (arr) {
        var view = this,
          all = view.product_status.filter((e) => e.key !== "ALL"),
          selected = arr.filter((e) => e === "ALL"),
          saved = view.$store.getters["EcTempProduct/getProductStatus"],
          flag = true;
        if (selected.length > 0) {
          if (
            !(
              saved.filter((e) => e.key !== "ALL").length >=
              arr.filter((e) => e.key !== "ALL").length
            )
          ) {
            all.forEach(function (e) {
              arr.push(e.key);
            });
          } else {
            arr = arr.filter((e) => e !== "ALL");
            flag = false;
          }
        }
        if (saved.includes("ALL") && !arr.includes("ALL")) {
          if (flag) {
            arr = new Array();
          }
        }
        arr = [...new Set(arr)];
        view.$store.dispatch("EcTempProduct/setProductStatus", arr);
      },
    },
    desserts: {
      get: function () {
        return this.$store.getters["EcTempProduct/getDesserts"];
      },
      set: function () {},
    },
    totalDesserts: {
      get: function () {
        return this.$store.getters["EcTempProduct/getTotalDesserts"];
      },
      set: function () {},
    },
    totRecords: {
      get: function () {
        return this.$store.getters["EcTempProduct/getTotRecords"];
      },
      set: function () {},
    },
    pageCount: {
      get: function () {
        return this.$store.getters["EcTempProduct/getPageCount"];
      },
      set: function () {},
    },
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    totPage: function () {
      return this.lastPage;
    },
    getPages: function () {
      var view = this;
      return view.$utils.getPages(
        view.lastPage,
        view.currentPage,
        view.totPage
      );
    },
    currentPage: function () {
      return this.$store.getters["EcTempProduct/getPage"];
    },
    lastPage: function () {
      return this.$store.getters["EcTempProduct/getLastPage"];
    },
    perpage: function () {
      return this.$store.getters["EcTempProduct/getPerPage"];
    },
    page: function () {
      return this.$store.getters["EcTempProduct/getPage"];
    },
    selectedEndPointUrls: function () {
      let endpoints = [];
      let view = this;
      if (view.selected.length > 0) {
        view.desserts.forEach(function (item) {
          view.selected.forEach((o) => {
            if (item.product_id == o.product_id) {
              let enpointURL = "";
              if (o.muid === null || o.muid == 0 || o.muid == o.product_id)
                enpointURL =
                  view.$url("ADD_PRODUCT_FORM_URLV2") +
                  o.product_id +
                  "?ec_content_type=manual_product";
              else
                enpointURL =
                  view.$url("ADD_PRODUCT_FORM_URLV2") +
                  o.product_id +
                  "?ec_content_type=email";
              endpoints.push({ product_id: o.product_id, url: enpointURL });
            }
          });
        });
      }
      return endpoints;
    },
  },
  methods: {
    get_product_comments: function (product_id) {
      var view = this;
      let endpoint = view.$url("ASSING_USER_URL") + "?product_id=";
      //let endpoint = "http://0.0.0.0:5054/compare_pdf/12"
      view
        .$fetch({ requiresAuth: true })
        .get(endpoint + product_id)
        .then((response) => {
          view.comment_data = response.data.assigned;
          view.isLoading = false;
        })
        .catch((e) => console.log(e));
    },
    close_comment_dialog(value) {
      var view = this;
      view.comment_dialog = false;
      // view.comment_close_click = true
    },
    open_comment_dialog(item) {
      var view = this;
      view.comment_dialog = true;
      view.get_product_comments(item.product_id);
      view.comment_product_id = item.product_id;
    },
    checkIfOperationExistForModule(type) {
      return this.$utils.checkIfOperationExistForModule(this, type);
    },
    addnewproduct(channel_id) {
      var view = this;
      let enpointURL =
        view.$url("ADD_NEW_PRODUCT") + "?channel_id=" + channel_id;
      let popup = window.open(
        enpointURL,
        "Add Product",
        "width=900,height=700"
      );
    },
    formateDate: function (d) {
      return this.$utils.formateDate(d);
    },
    manage_pdf(product_id) {
      var view = this;
      let url = view.$url("MANANGE_PDF_URL") + product_id;
      url = url.replace(/%20/g, " ");
      window.open(
        url,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=100,,width=800,height=800"
      );
    },
    displayStatus: function (i) {
      var view = this,
        all_status = view.$store.getters["Rawdata/getProductStatus"],
        filtered = all_status.filter((o) => o.key_id === i);
      if (Array.isArray(filtered) && filtered.length > 0) {
        return filtered[0].description;
      }
      return "";
    },
    showButtons: function (o) {
      this.displayButtons = !this.displayButtons;
    },
    massupdatedialogStatus(o) {
      var view = this;
      view.massupdatedialog = false;
      view.selected = [];
      if (o == "save") view.getData();
    },
    massproductupdate() {
      var view = this;
      if (view.selected.length > 0) {
        view.massupdatedialog = true;
      } else {
        view.$swal.fire({
          position: "bottom-end",
          icon: "error",
          title: "Please select ProductID",
        });
      }
    },
    deleteproduct() {
      var view = this;
      if (view.selected.length > 0) {
        view.$swal
          .fire({
            title: "Are you sure ?",
            text: "This will move product in archive section.",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes, remove it !",
            cancelButtonText: "No, cancel !",
            closeOnConfirm: false,
            closeOnCancel: false,
          })
          .then((result) => {
            if (result.isConfirmed) {
              var posted_data = {};
              var data = [];
              for (var l in this.selected) {
                let local = {};
                local["muid"] = this.selected[l].muid;
                local["product_id"] = this.selected[l].product_id;
                local["index_name"] = this.selected[l].index_name;
                local["product_status"] = this.selected[l].product_status;
                data.push(local);
              }
              posted_data["data"] = data;
              let delete_data = JSON.stringify(posted_data);
              let delelete_endpoint = view.$url("SOFT_DELETE");
              this.$fetch({ requiresAuth: true })
                .post(delelete_endpoint, posted_data)
                .then((response) => {
                  this.$swal.fire({
                    icon: "success",
                    title: "Product Deleted",
                    text: "Product has been deleted successfully",
                  });
                  view.getData();
                })
                .catch(function (response) {
                  if (response.response.status == 401) {
                    window.location.href;
                  }
                });
              view.selected = [];
              console.log(JSON.stringify(posted_data));
              // delete endpoint
            } else {
              view.selected = [];
            }
          });
        //console.log(this.selected)
      } else {
        view.$swal.fire({
          position: "bottom-end",
          icon: "error",
          title: "Please select ProductID",
        });
      }
    },
    displaypreview(product_id) {
      this.imagelist = [];
      this.preview_loaded = false;
      this.hover_product_id = product_id;
      let manage_image =
        this.$url("CLIENT_FRONTEND") + "managepdf/" + product_id;
      this.manage_pdf_url = manage_image;
      let token = this.$utils.getAccessToken();
      //this.loading = true
      let temproduct_url = this.$url("PRODUCT_PREVIEW");
      this.pdf_link =
        this.$url("DIRECT_MAIL_PDF") + "?product_id=" + product_id;
      this.$fetch({ requiresAuth: true })
        .get(
          temproduct_url +
            "?product_id=" +
            product_id +
            "&product_type=ProductImg",
          {
            //headers
            headers: {
              Authorization: `${token}`,
            },
          }
        )
        .then((response) => {
          let items = response.data.payload;
          this.preview_loaded = true;
          this.imagelist = items;
        })
        .catch(function (response) {
          console.log(response);
        });
    },
    filterdata() {
      this.getData();
    },
    reset() {
      var view = this;
      view.$store
        .dispatch("EcTempProduct/resetSearch")
        .then(function () {
          return view.$store.dispatch("EcTempProduct/setPerPage", 10);
        })
        .then(function () {
          return view.$store.dispatch("EcTempProduct/setIsSearch", true);
        })
        .then(function () {
          return view.$store.dispatch("EcTempProduct/setProductStatus", []);
        })
        .then(function () {
          return view.$store.dispatch("EcTempProduct/setMediaChannels", [
            view.$productrouteconst.EMAIL,
          ]);
        })
        .then(function () {
          view.sortBy = "modify_date";
          view.sortDesc = true;
          view.getData();
        });
    },
    getDataByPage(n) {
      let view = this,
        paging = view.$store.getters["EcTempProduct/getPaging"],
        obj = paging.filter((e) => e.page_number === n);
      view.$store
        .dispatch("EcTempProduct/setPage", n)
        .then(function () {
          return view.$store.dispatch("EcTempProduct/setPagingObj", obj);
        })
        .then(function () {
          view.getData();
        });
    },
    handlePerPage(n) {
      let view = this;
      view.$store
        .dispatch("EcTempProduct/setPage", 1)
        .then(function () {
          return view.$store.dispatch("EcTempProduct/setPerPage", n);
        })
        .then(function () {
          view.options.itemsPerPage = n;
          view.getData();
        });
    },
    paginate(n) {
      if (
        parseInt(n) === parseInt(0) &&
        parseInt(this.currentPage) === parseInt(1)
      ) {
        return;
      }
      if (parseInt(n) === parseInt(-1)) {
        if (parseInt(this.currentPage) <= parseInt(1)) {
          return;
        }
      }
      if (parseInt(n) === parseInt(1)) {
        if (parseInt(this.currentPage) >= parseInt(this.lastPage)) {
          return;
        }
      }
      let view = this,
        paging = view.$store.getters["EcTempProduct/getPaging"],
        obj = [];
      n =
        parseInt(n) === parseInt(0)
          ? parseInt(1)
          : parseInt(n) + parseInt(view.currentPage);
      obj = paging.filter((e) => e.page_number === n);
      view.$store
        .dispatch("EcTempProduct/setPagingObj", obj)
        .then(function () {
          return view.$store.dispatch("EcTempProduct/setPage", n);
        })
        .then(function () {
          view.getData();
        });
    },
    last() {
      let view = this;
      view.$store
        .dispatch("EcTempProduct/setPage", view.lastPage)
        .then(function () {
          view.getData();
        });
    },
    openlogmodal(product_id, muid) {
      var view = this;
      view.isLoading = true;
      view.productadmindialog = true;
      view.loading = true;
      view.logdata = [];
      let logurl =
        view.$url("PRODUCT_ADMIN_LOG") + product_id + "?type=publish";
      let token = view.$utils.getAccessToken();
      this.$fetch({ requiresAuth: true, operation: "view", vueScope: view })
        .get(logurl, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((response) => {
          view.loading = false;
          view.isLoading = false;
          view.logdata = response.data.payload;
          view.logmuid = muid;
        })
        .catch(function (response) {
          view.isLoading = false;
          if (response.response.status == 401) {
            //window.location.href
            view.loading = true;
          }
        });
    },
    getData: function () {
      let view = this,
        data = {};
      view.loading = true;
      view.isLoading = true;
      view.$store.dispatch(
        "EcTempProduct/setAllowedSectors",
        view.$store.getters["Rawdata/getSectors"]
      );
      data["request"] = view.$store.getters["EcTempProduct/getRequestPayload"];
      console.log("data being called",data, view.$store.getters["EcTempProduct/getIsSearch"]);
    
      if (view.$store.getters["EcTempProduct/getIsSearch"]) {
        data["search"] = view.$store.getters["EcTempProduct/getSearch"];
      }
      view
        .$fetch({
          requiresAuth: true,
          operation: "add",
          vueScope: view,
        })
        .post(view.$url("TEMPPRODUCT"), data)
        .then((response) => {
          let data = response.data.payload,
            lastPage = 0;
          view.desserts = data.payload;
          lastPage = Math.ceil(
            data.totalProducts / view.$store.getters["EcTempProduct/getPerPage"]
          );
          console.log("lastPage", lastPage);
          view.$store.dispatch("EcTempProduct/setDesserts", data.payload);
          view.$store.dispatch(
            "EcTempProduct/setTotalDesserts",
            data.totalProducts
          );
          view.$store.dispatch(
            "EcTempProduct/setTotRecords",
            data.totalProducts
          );
          view.$store.dispatch("EcTempProduct/setPageCount", lastPage);
          view.$store.dispatch("EcTempProduct/setExtraData", data.extra_data);
          view.$store.dispatch(
            "EcTempProduct/setTotProducts",
            data.totalProducts
          );
          view.$store.dispatch("EcTempProduct/setLastPage", lastPage);
          if ("paging" in data) {
            if (Array.isArray(data.paging)) {
              view.$store.dispatch("EcTempProduct/setPaging", data.paging);
            }
          }
          view.loading = false;
          view.isLoading = false;
          view.selected = [];
        })
        .catch(function (e) {
          view.loading = false;
          view.isLoading = false;
          console.log(e);
        });
    },
    addItemV2(item) {
      var view = this;
      let enpointURL = "";
      if (item.muid === null || item.muid == 0 || item.muid == item.product_id)
        enpointURL =
          view.$url("ADD_PRODUCT_FORM_URLV2") +
          item.product_id +
          "?ec_content_type=manual_product";
      else
        enpointURL =
          view.$url("ADD_PRODUCT_FORM_URLV2") +
          item.product_id +
          "?ec_content_type=email";
      window.open(enpointURL, "_blank");
    },
    addItem(item) {
      var view = this;
      let enpointURL = "";
      if (item.muid === null || item.muid == 0 || item.muid == item.product_id)
        enpointURL =
          view.$url("ADD_PRODUCT_FORM_URL") +
          item.product_id +
          "?ec_content_type=manual_product";
      else
        enpointURL =
          view.$url("ADD_PRODUCT_FORM_URL") +
          item.product_id +
          "?ec_content_type=email";
      window.open(enpointURL, "_blank");
    },
    editItem(item) {
      var view = this;
      let muid = "";
      muid = item.muid;
      if (
        item.muid === null ||
        item.muid == 0 ||
        item.muid == item.product_id
      ) {
        let enpointURL =
          view.$url("EDIT_PRODUCT_FORM_URL") +
          item.product_id +
          "?ec_content_type=manual_product";
        window.open(enpointURL, "_blank");
      } else {
        let enpointURL =
          view.$url("EDIT_PRODUCT_FORM_URL") +
          item.product_id +
          "?ec_content_type=email";
        window.open(enpointURL, "_blank");
      }
    },
    companyName(id) {
      let extradata = this.$store.getters["EcTempProduct/getExtraData"];
      return id in extradata
        ? "company_name" in extradata[id]
          ? this.$utils.checkVal(extradata[id]["company_name"])
          : "--"
        : "--";
    },
    mchannelSectorAudience(i) {
      let view = this,
        str = "",
        str1 = "",
        extradata = view.$store.getters["EcTempProduct/getExtraData"],
        sec_name = "",
        pid = i.product_id,
        audience = "";
      // str= str + view.$utils.checkVal(i.mChannelName) + '/'
      // sec_name = pid in extradata ? ('sector_name' in extradata[pid] ? extradata[pid]['sector_name'] : '--') : '--'
      // str = str + view.$utils.checkVal(sec_name) + '/'
      for (var i = 0; i <= extradata[pid]["sector_name"].length - 1; i++) {
        str1 = str1 + extradata[pid]["sector_name"][i] + ",";
      }
      str = str1.substring(0, str1.length - 1) + "/";
      audience =
        pid in extradata
          ? "audience" in extradata[pid]
            ? extradata[pid]["audience"]
            : "--"
          : "--";
      str = str + view.$utils.checkVal(audience);
      return str;
    },
    pdfFile(i) {
      let extradata = this.$store.getters["EcTempProduct/getExtraData"];
      let val = extradata[i]["document_exist"];
      return val;
    },
    userName(i) {
      let extradata = this.$store.getters["EcTempProduct/getExtraData"],
        val =
          i in extradata
            ? "admin_user" in extradata[i]
              ? extradata[i]["admin_user"]
              : "--"
            : "--";
      return val; //this.$utils.checkVal(val)
    },
    search: function () {
      var view = this;
      view.$store
        .dispatch("EcTempProduct/setPage", 1)
        // .then(function () {
        //   return view.$store.dispatch("EcTempProduct/setPerPage", 10);
        // })
        .then(function () {
          return view.$store.dispatch("EcTempProduct/setIsSearch", true);
        })
        .then(function () {
          view.getData();
        });
    },
    addCompany: function (c) {
      this.$store.dispatch("EcTempProduct/addCompany", c);
    },
    removeCompany: function (c) {
      this.$store.dispatch("EcTempProduct/removeCompany", c);
    },
  },
  watch: {
    options: {
      handler(o) {
        var view = this,
          sortBy = o.sortBy,
          sortDesc = o.sortDesc;
        if (Array.isArray(sortBy) && Array.isArray(sortDesc)) {
          if (sortBy.length > 0 && sortDesc.length > 0) {
            view.$store
              .dispatch("EcTempProduct/setPage", 1)
              .then(function () {
                return view.$store.dispatch("EcTempProduct/setSorting", {
                  field: sortBy[0],
                  direction: sortDesc[0],
                });
              })
              .then(function () {
                view.getData();
              });
          }
        }
      },
    },
  },
};
</script>

<style lang="sass" scoped>
@import './../../sass/product'
</style>
<style>
.previewcontainer {
  position: relative;
}

.pdfcontent {
}

.v-icon--right {
  margin-left: 0px;
}

.complist ul {
  padding-left: 0;
  list-style: none;
}

.complist li {
  list-style: none;
  float: none;
}

.complist span {
  position: relative;
  margin: 0 5px 0 5px;
  font-size: 14px;
  cursor: pointer;
  color: #00a4e4;
}

.sectorlist ul {
  padding-left: 0;
  list-style: none;
}

.sectorlist ul li {
  float: none;
}

.sectorlist ul li {
  margin: 0 5px 0 5px;
  font-size: 14px;
  cursor: pointer;
  color: #00a4e4;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 11px;
  font-weight: bold;
  color: #fff !important;
}

.mx-3 {
  overflow-x: hidden;
}

.v-menu__content {
  box-shadow: none;
}

.v-application .text-center {
  text-align: left !important;
}
</style>
